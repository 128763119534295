var ItemSelCreate = require('components/ItemSelCreate');

var Form = function (view) {
    this.view = view;
    this._bind();
    this.bindInvitationsSelector();
    this.bindParticipationsSelector();
    this.bindEmailVerif();
    this.bindFederationSelector();
    this.bindTaxesSelector();
    this.bindSelectUrl();
    this.bindCateg();
};

Form.prototype = {
    _bind: function () {
        var self = this;
        ItemSelCreate.initialize(this.view);
        self.view.find('.js-uppercase').each(function (e) {
            var val = $(this).val();
            $(this).val(val.toUpperCase());
        });
        self.view.on('change', '.js-uppercase', function (e) {
            var val = $(this).val();
            $(this).val(val.toUpperCase());
        });
        if (self.view.find('.js-datepicker').data("DateTimePicker")) {
            self.view.find('.js-datepicker').data("DateTimePicker").format = 'DD/MM/YYYY';
        }

        $('[data-toggle]').on('click', function (e) {
            e.preventDefault();
            let selector = $(this).data('toggle');
            $(selector).toggleClass('hidden');
        })

        var country = self.view.find('.js-country');
        if (country.length > 0 && $(country).val() !== "") {
            $.get("https://ipinfo.io", function () {
            }, "jsonp").always(function (resp) {
                var countryCode = (resp && resp.country) ? resp.country : "";
                $(country).val(countryCode);
            });
        }
        self.view.on('change', '.js-fileupload', function (e) {
            var fileInput = $(this);
            var filePath = fileInput.value;
            var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            if (!allowedExtensions.exec(filePath)) {
                alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
                fileInput.value = '';
                return false;
            } else {
                console.log('ok');
            }
        });
    },

    bindInvitationsSelector: function (onlyRender) {
        var target = this.view.find(".js-invitations");
        var input = target.find('input.js-value');
        var data = {
            competitions: []
        };

        function loadValue() {
            var rawdata = input.val() || '[]';
            data.competitions = $.parseJSON(rawdata);
        }

        function dumpValue() {
            var rawdata = JSON.stringify(data.competitions);
            input.val(rawdata);
        }

        function isCompetitionSelected(competitionId) {
            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });
            return comp ? true : false;
        }

        function isSubSelected(competitionId, id) {

            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });
            if (comp) {
                var y = _.find(comp.results, function (y) {
                    return (y === id);
                });
                return y ? true : false;
            }
            return false;
        }

        function syncView() {
            var inputCompetitions = target.find('.js-competition');
            var inputSubs = target.find('.js-competition-sub');
            var locked = target.closest('.wz-screen').data('locked') == '1';

            _.each(inputCompetitions, function (input) {
                var input = $(input);
                var competitionId = parseInt(input.data('id'), 10);
                var selected = isCompetitionSelected(competitionId);
                input.prop('checked', selected);
                if (locked) {
                    input.prop('disabled', true);
                }
                if (selected) {
                    input.closest('.js-competition-container').addClass('selected');
                } else {
                    input.closest('.js-competition-container').removeClass('selected');
                }

            });

            _.each(inputSubs, function (input) {
                var input = $(input);
                var competitionId = parseInt(input.data('competition'), 10);
                var id = parseInt(input.data('id'), 10);
                var selected = isSubSelected(competitionId, id);

                input.prop('checked', selected);
                if (locked) {
                    input.prop('disabled', true);
                }

            });

        }

        function toggleCompetition(competitionId) {
            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });

            if (comp) {
                data.competitions = _.without(data.competitions, comp);
            } else {
                comp = {id: competitionId, results: []};
                data.competitions.push(comp);
            }

            dumpValue();
            syncView();
        }

        function toggleSub(competitionId, id) {

            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });

            if (!comp) {
                console.log('warning: data desynchronized.');

            } else {

                var y = _.find(comp.results, function (y) {
                    return (id === y);
                });

                if (y) {
                    comp.results = _.without(comp.results, id);
                } else {
                    comp.results.push(id);
                }

                dumpValue();
                syncView();

            }

        }

        if (!onlyRender) {
            target.on('click', ' .js-competition', function (e) {
                var competitionId = parseInt($(this).data('id'), 10);
                toggleCompetition(competitionId);
            });

            target.on('click', ' .js-competition-sub', function (e) {
                var competitionId = parseInt($(this).data('competition'), 10);
                var id = parseInt($(this).data('id'), 10);
                toggleSub(competitionId, id);
            });
        }

        loadValue();
        syncView();

    },


    bindParticipationsSelector: function (onlyRender) {
        var target = this.view.find(".js-participations");
        var input = target.find('input.js-value');
        var data = {
            competitions: []
        };

        function loadValue() {
            var rawdata = input.val() || '[]';
            data.competitions = $.parseJSON(rawdata);
        }

        function dumpValue() {
            var rawdata = JSON.stringify(data.competitions);
            input.val(rawdata);
        }

        function isCompetitionSelected(competitionId) {
            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });
            return comp ? true : false;
        }

        function isSubSelected(competitionId, id) {
            var selected = getSubSelected(competitionId, id);
            return !!selected;
        }

        function getSubSelected(competitionId, id) {

            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });
            if (comp) {
                var y = _.find(comp.categories, function (y) {
                    return (y.id === id);
                });
                return y;
            }
            return null;
        }

        function syncView() {
            var inputCompetitions = target.find('.js-competition');
            var inputSubs = target.find('.js-competition-sub');
            var inputNumbers = target.find('.js-competition-sub-number');

            var otherChampionship = target.find('.js-competition-other');
            var otherChampionshipName = target.find('.js-competition-other-name');

            var locked = target.closest('.wz-screen').data('locked') == '1';

            _.each(inputCompetitions, function (input) {
                var input = $(input);
                var competitionId = parseInt(input.data('id'), 10);
                var selected = isCompetitionSelected(competitionId);
                input.prop('checked', selected);
                if (locked) {
                    input.prop('disabled', true);
                }
                if (selected) {
                    input.closest('.js-competition-container').addClass('selected');
                } else {
                    input.closest('.js-competition-container').removeClass('selected');
                }

            });

            _.each(inputSubs, function (input) {
                var input = $(input);
                var competitionId = parseInt(input.data('competition'), 10);
                var id = parseInt(input.data('id'), 10);
                var selected = isSubSelected(competitionId, id);

                input.prop('checked', selected);
                if (locked) {
                    input.prop('disabled', true);
                }

                // on affiche le choix de model :
                if (selected) {
                    input.parent().next(".js-competition-sub-model").show();
                } else {
                    input.parent().next(".js-competition-sub-model").hide();
                }

            });


            _.each(inputNumbers, function (input) {
                var input = $(input);
                var competitionId = parseInt(input.data('competition'), 10);
                var id = parseInt(input.data('id'), 10);
                var selected = getSubSelected(competitionId, id);
                if (selected)
                    input.val(selected.number);
                if (locked) {
                    input.prop('disabled', true);
                }

            });

            _.each(otherChampionship, function (input) {
                var input = $(input);
                var year = parseInt(input.data('year'), 10);
                var comp = _.find(data.competitions, function (c) {
                    return (c.id === -1 && c.year === year);
                });
                input.prop("checked", !!comp);
                var div = target.find('.js-other[data-year=' + year + ']');
                if (!!comp)
                    div.show();
                else
                    div.hide();
            });
            _.each(otherChampionshipName, function (input) {
                var input = $(input);
                var year = parseInt(input.data('year'), 10);
                var comp = _.find(data.competitions, function (c) {
                    return (c.id === -1 && c.year === year);
                });
                if (comp)
                    input.val(comp.name);
            });

        }

        function toggleCompetition(competitionId) {
            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });

            if (comp) {
                data.competitions = _.without(data.competitions, comp);
            } else {
                comp = {id: competitionId, categories: []};
                data.competitions.push(comp);
            }

            dumpValue();
            syncView();
        }

        function toggleSub(competitionId, id) {

            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });

            if (!comp) {
                console.log('warning: data desynchronized.');

            } else {

                var y = _.find(comp.categories, function (y) {
                    return (id === y.id);
                });

                if (y) {
                    comp.categories = _.without(comp.categories, y);
                } else {
                    comp.categories.push({id: id, number: ''});
                }

                dumpValue();
                syncView();

            }

        }

        function toggleSubNumber(competitionId, id, number) {
            var comp = _.find(data.competitions, function (c) {
                return (c.id === competitionId);
            });

            if (!comp) {
                console.log('warning: data desynchronized.');

            } else {

                var y = _.find(comp.categories, function (y) {
                    return (id === y.id);
                });

                if (y) {
                    y.number = number;
                }

                dumpValue();
                syncView();

            }

        }

        function toggleOther(year) {
            var comp = _.find(data.competitions, function (c) {
                return (c.id === -1 && c.year === year);
            });
            if (comp) {
                data.competitions = _.without(data.competitions, comp);
            } else {
                comp = {id: -1, year: year, category: "", number: ""};
                data.competitions.push(comp);
            }
            dumpValue();
            syncView();
        }

        function setOtherName(year, name) {
            var comp = _.find(data.competitions, function (c) {
                return (c.id === -1 && c.year === year);
            });
            if (comp) {
                comp.name = name;
            }
            dumpValue();
            syncView();
        }


        if (!onlyRender) {
            target.on('click', ' .js-competition', function (e) {
                var competitionId = parseInt($(this).data('id'), 10);
                toggleCompetition(competitionId);
            });

            target.on('click', ' .js-competition-sub', function (e) {
                var competitionId = parseInt($(this).data('competition'), 10);
                var id = parseInt($(this).data('id'), 10);
                toggleSub(competitionId, id);
            });
            target.on('keyup', ' .js-competition-sub-number', function (e) {
                var competitionId = parseInt($(this).data('competition'), 10);
                var id = parseInt($(this).data('id'), 10);
                toggleSubNumber(competitionId, id, $(this).val());
            });

            target.on('click', ' .js-competition-other', function (e) {
                toggleOther(parseInt($(this).data('year'), 10));
            });
            target.on('keyup', ' .js-competition-other-name', function (e) {
                var year = parseInt($(this).data('year'), 10);
                setOtherName(year, $(this).val());
            });
        }

        loadValue();
        syncView();

    },

    bindSelectUrl: function() {
        $(".js-selecturl").on('change', function () {
            window.location = $(this).val();
        });
    },
    bindTaxesSelector: function (onlyRender) {
        //
        // SELECT TAXES AND CHANGE VALUE ON CHANGE
        //
        if ($('#taxes_VATStatus').val()) {
            $($('.js-select-vat')[$('#taxes_VATStatus').val() - 1]).prop("checked", true);
        }

        $("body").on('click', ".js-select-vat", function () {

            $('#taxes_VATStatus').val($(this).val());
        });
    },

    bindFederationSelector: function (onlyRender) {
        $('.js-select-deliverer').off('change');
        $('.js-select-deliverer').on('change', function (e) {
            var select = $(this);
            var selectedChoice = $(this).val();
            var url = select.data('url');
            $.ajax({
                type: 'GET',
                url: url + '?country=' + selectedChoice,
                success: function (data) {
                    select.parents('.form-group').next().find('.js-label-deliverer').val(data.value);
                }
            });

        });
    },

    bindEmailVerif: function (onlyrender) {
        $('.js-email').on('input', function () {
            if (ValidateEmail(this.value)) {
                this.classList.add('has-error');
            } else {
                this.classList.remove('has-error');
                this.classList.add('has-success');
            }
        });
    },

    bindCateg: function () {
        if ($(".js-championshipref").val() == "2") {
            // ELMS only
            let self = this;
            this.toggleMLMC();
            $(".js-category").on("change", function () {
                self.toggleMLMC();
            })
        } else {
            let mlmc = $(".js-convert-mlmc");
            mlmc.hide();
            mlmc.find("select").prop("required", "");
        }
    },
    toggleMLMC: function () {
        let categ = $(".js-category").val();
        let mlmc = $(".js-convert-mlmc");
        if (categ == "6") {
            // LMP3 : afficher le choix de conversion LMC
            mlmc.show();
            mlmc.find("select").prop("required", "required");
        } else {
            mlmc.hide();
            mlmc.find("select").prop("required", "");
        }
    }

};

var api = {

    initialize: function (form_selector) {
        return new Form($(form_selector));
    }
};

module.exports = api;


function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return false;
    }
    return true;
}