var Templating = require('services/Templating');

/**
 * Create and bind a ItemSelCreate
 *
 * @constructor
 * @class      GalleryUpload
 * @param      {jquery node}  view  The view
 */
var ItemSelCreate = function (view) {

    this.view = view;
    this.id = this.view.data("id");
    this.searchbox = this.view.find('.js-select-create');
    this.picked = this.view.find('.js-picked');
    this.noitem = this.view.find('.js-noitem');

    this.search = this.view.find('.js-search-item');
    this.resetbtn = this.view.find('.js-reset');
    this.results = this.view.find('.js-search-results');
    this.noresults = this.view.find('.js-no-results');
    this.create = this.view.find('.js-create-form');
    this.searchname = this.view.find('.js-search-name');

    this.conteneur = this.view.find('.conteneur');

    this.template = $(".js-template-item").html();
    this.selecting = false;
    this.hidden = this.view.find('input[type="hidden"]').eq(0);
    this.context_hidden = this.view.find('input[type="hidden"]').eq(1);

    this.url_get = this.hidden.data('src');
    this._bind();
};

/**
 * Initialize the instance
 */
ItemSelCreate.prototype = {

    _bind: function () {
        var self = this;
        self.prefix = self.hidden.attr("name").split('[')[0];

        // on ajoute la classe js-form-id au div pour pouvoir injecter le formulaire depuis l'appProxy au bon endroit
        this.create.find("#js-form").attr("id", "js-form-" + self.id);

        this.hidden.change(function (e) {
            self.select($(this).val());
        });

        this.context_hidden.change(function (e) {
            // changement de contexte : vérifier que le choix est toujours valide ?
            console.log("reset",self.url_get);
            self.reset();
        });

        this.view.on('click', '.js-open-search,.js-picked,.js-noitem', function (e) {
            e.preventDefault();
            self.searchbox.show();
            self.showSearch();

            // on crée tout de suite le formulaire
            var url = $(".js-btn-create").data('href') + '/' + self.id + "?context=" + self.context_hidden.val();
            App.GET(url);
        });

        this.view.on('click', '.js-reset', function (e) {
            e.preventDefault();
            self.reset();
        });

        this.view.on('click', '.js-close', function (e) {
            e.preventDefault();
            self.clearUi();
            self.searchbox.hide();
        });

        this.view.on('click', '.js-btn-create', function (e) {
            e.preventDefault();
            var name = self.searchname.val();
            var url = $(this).data('href') + '/' + self.id;
            if (name) url += '/' + name;
            url += "?context=" + self.context_hidden.val()
            App.GET(url);
            self.showCreate();
        });
        this.view.on('click', '.js-btn-cancel', function (e) {
            e.preventDefault();
            self.showSearch();
        });

        this.view.on('keyup', '.js-search-name', function (e) {
            if ($(this).val() == "") {
                self.data = [];
                self.results.html("");
                self.noresults.show();
            } else if ($(this).val().length > 1) {
                var url = $(this).data('src') + '/' + $(this).val() + "?id=" + self.id + "&context=" + self.context_hidden.val();
                $.getJSON(url, null, function (data) {
                    self.data = data;
                    self.results.html(Templating.renderString(self.template, {data: data}));
                    if (data.length > 0) self.noresults.hide();
                    else  self.noresults.show();
                });
            }
        });

        this.view.on('click', '.js-item-select', function (e) {
            e.preventDefault();
            self.selecting = false;
            var id = $(this).data("id");
            self.select(id);
        });

    },
    showSearch: function () {
        this.conteneur.css("left", 0);
    },
    showCreate: function () {
        this.conteneur.css("left", "-102%");
    },
    setContext: function(ctxt) {
        this.context = ctxt;
    },
    select: function (id) {
        var self = this;
        if (id=="") {
            self.picked.addClass("hidden");
            self.noitem.removeClass('hidden');
            return;
        }

        var item = _.find(self.data, {'id': id});
        if (item) {
            self.selectItem(item);
            return;
        }
        $.getJSON(self.url_get + '/' + id, null, function (data) {
            item = data;
            self.selectItem(item);
        });

    },
    clearUi: function () {
        this.data = [];
        this.searchname.val("");
        this.results.html("");
        this.showSearch();
    },
    setFile: function(fieldName,file) {
        var fileWidget = this.view.parents('form').eq(0).find('input[name="file_' + this.prefix + '_' + fieldName + '"]').parents(".js-file").first();
        App.formUtils.addFile(fileWidget,file);
    },
    selectItem: function (item) {
        var self = this;
        self.picked.html(item.title);
        self.picked.removeClass('hidden');
        self.noitem.addClass('hidden');
        self.resetbtn.removeClass('hidden');

        // propager les données à tous les champs configurés
        var tabAssoc = eval("tabAssoc" + self.id);
        for (var formField in tabAssoc) {

            var field = tabAssoc[formField];
            var type = 'text';
            if (_.isObject(field)) {
                // on a spécifié le type :
                type = field.type;
                field = field.field;
            }

            formField = formField.replace(".", '][');
            var tab = field.split(".");
            var data = item;
            for (var i in tab) {
                data = data[tab[i]];
            }

            if (type=='file') {

                var fileWidget = self.view.parents('form').eq(0).find('input[name="file_' + self.prefix + '_' + formField + '"]').parents(".js-file").first();
                if (data) {
                    // on appelle le service de conversion file => fileupload
                    $.getJSON(urlFileConvert + "/" + self.id + "/" + formField + "/" + data.id,null,function(response) {
                        // on renvoie le fichier à la bonne instance (cas d'appel multiple)
                        ItemSelCreate.find(response.component).setFile(response.field,response.files[0]);
                        //App.formUtils.addFile(fileWidget,response.files[0]);
                    })

                } else {
                    App.formUtils.removeFile(fileWidget);
                }

            } else {
                var search = "[name='" + self.prefix + "[" + formField + "]']";
                if (formField.indexOf("context")>=0) search=search.replace("\[context\]","").replace("name='","name='context");
                self.view.parents('form').eq(0).find(search).each(function (i, e) {
                    switch (type) {
                        case 'date':
                            if (data) {
                                var d = new Date(data);
                                month = d.getMonth() + 1;
                                day = ("0" + d.getDate()).slice(-2);
                                month = ("0" + month).slice(-2);
                                data = day + '/' + month + '/' + d.getFullYear();
                            }
                            break;
                    }

                    var formatData = (typeof data ==="string" && $(e).hasClass('js-uppercase')) ? data.toUpperCase() : data;

                    $(e).val(formatData);

                    if (!self.selecting && $(e).attr("type") == "hidden") {
                        $(e).trigger('change');
                    }

                    if($(e).hasClass('js-selector'))
                        $(e).trigger('change');
                });
            }
        }

        self.selecting = true;
        self.searchbox.hide();
        self.clearUi();
    },
    reset: function () {
        var self = this;
        self.picked.html("");
        self.picked.addClass('hidden');
        self.noitem.removeClass('hidden');
        self.resetbtn.addClass('hidden');
        self.selecting = false;

        // propager les données à tous les champs configurés
        var tabAssoc = eval("tabAssoc" + self.id);
        for (var formField in tabAssoc) {
            var field = tabAssoc[formField];
            var type = 'text';
            if (_.isObject(field)) type = field.type;

            formField = formField.replace(".", '][');


            if (type=="file") {
                var fileWidget = self.view.parents('form').eq(0).find('input[name="file_' + self.prefix + '_' + formField + '"]').parents(".js-file").first();
                App.formUtils.removeFile(fileWidget);
            } else {
                var search = "[name='" + self.prefix + "[" + formField + "]']";
                if (formField.indexOf("context")>=0) search=search.replace("\[context\]","").replace("name='","name='context");
                self.view.parents('form').eq(0).find(search).each(function (i, e) {
                    $(e).val("");
                    if (!self.selecting && $(e).attr("type") == "hidden") {
                        $(e).trigger('change');
                    }
                });
            }
        }

    }
}
;

/**
 * Initialize an item selector for each element which have class 'js-sel-create' in the form
 *
 */
ItemSelCreate.initialize = function (form_selector) {
    if (!ItemSelCreate.instances)  ItemSelCreate.instances = new Array();
    form_selector.find('.js-sel-create').each(function (index) {
        ItemSelCreate.instances.push(new ItemSelCreate($(this)));
    });
};
ItemSelCreate.find = function (id) {
    return _.find(ItemSelCreate.instances, {'id': id});
};

module.exports = ItemSelCreate;
